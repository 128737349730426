@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

html, body {
  max-width: 100%;
  overflow-x: hidden;
 
}

@media (min-width: 992px) {
  .rotate-lg-6 {
    transform: rotate(6deg);
  }
}

.fancy-border-radius {
  border-radius: 53% 47% 52% 48% / 36% 41% 59% 64%;
}

@media (min-width: 992px) {
  .rotate-lg-6 {
    transform: rotate(6deg);
  }
}
  
/* Example styles for dark mode */
.dark {
 /* background-color: #1a1a1a; */
  background-image: url('https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8fDA%3D');
  opacity: 0.9;
  color: #fff;
  
}

/* Example styles for light mode */
.light {
  /*background-image: url('https://cliply.co/wp-content/uploads/2021/07/402107330_WHITE_WAVES_400px.gif'); */
  /* background-image: url('https://cdn.dribbble.com/users/32512/screenshots/3581570/white_abstract_by_gleb.gif'); */
  background-color: #fff;
  opacity: 0.9;
  background-repeat: no-repeat;
  color: #333;
}

/* light mode and night mode switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #613efc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slick-arrow.slick-prev {
  font-size: 10px;
  
}
.ant-carousel .slick-prev::before {
    content: '<';
    display: block;
    position: relative;
    bottom: 20px;
    right: 3px;
    /* width: 100px; */
    font-size: 25px;
    color: gray;
    background-color: white;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
   
}
.slick-arrow.slick-next {
  font-size: 10px;
}
.ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 3px;
  bottom: 20px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 33px;
  height: 33px;
}